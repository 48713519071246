/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #3b3a36; /* Track color - using a dark shaded brown */
}

::-webkit-scrollbar-thumb {
  background-color: #6d6b66; /* Thumb color - using a medium shaded brown */
  border-radius: 6px; /* Roundness of the scroll thumb */
  border: 2px solid #3b3a36; /* Creates padding around thumb - matching track color */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8a887b; /* Thumb color on hover - using a lighter shaded brown */
}
